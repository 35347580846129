import {BaseModel} from 'sjs-base-model';
import formatAddress from "../helpers/formatAddress";
import FormatDate from "../helpers/FormatDate";
import CategoryModel from "./CategoryModel";
import DisplayName from "../helpers/DisplayName";
import DisplayPrice from "../helpers/DisplayEuro";
import moment from 'moment'

export default class RentalModel extends BaseModel {
    RentalNr = null
    hirePeriod = '-'
    periodFrom = null
    periodUntil = null
    dateCreated = null
    startTime = ''
    endTime = ''
    renter = 'onbekend'
    amount = null
    stay = 'onbekend'
    totalPrice = null
    paymentStatus = null
    paymentDate = null
    status = null
    data = null
    partnerUserId = null
    delivery = null
    luggageHandling = null
    RentalItems = null
    holidayAddress = null
    uuid = null
    discountPercentage = null
    last_transaction_id = null
    partnerLocation = null
    webshopName = null
    identificationRequired = null

    constructor(data) {
        super();
        this.update(data);
    }

    update(data) {

        this.identificationRequired = data.identification_required

        this.status = data.bookingPhase.name
        this.partnerUserId = data.rental.partner_user_id
        this.totalPrice = DisplayPrice(Number.parseFloat(data.totalPrice).toFixed(2))
        this.RentalItems = this.formatRentalItems(data.rentalItems)
        this.data = data
        this.RentalNr = '#' + data.rental.id
        this.partnerLocation = data.partner_location.name
        this.webshopName = data.webshop_name

        if (data.rental.remarks) {
            this.RentalNr += '*'
        }

        this.dateCreated = FormatDate(data.rental.date_created)
        this.amount = this.countItems(data.rentalItems)

        if (data.rental.delivery) {
            this.delivery = true
        } else {
            this.delivery = false
        }

        if (data.rental.discount_percentage) {
            this.discountPercentage = data.rental.discount_percentage
        }

        if (data.rental.luggageHandling) {
            this.luggageHandling = true
        } else {
            this.luggageHandling = false
        }


        if (data.rental.date_rent_start && data.rental.date_rent_end) {

            this.hirePeriod = FormatDate(data.rental.date_rent_start) + ' - ' + FormatDate(data.rental.date_rent_end)
            this.periodFrom = FormatDate(data.rental.date_rent_start);
            this.startTime = moment(data.rental.date_rent_start).format('HH:mm')
            this.endTime = moment(data.rental.date_rent_end).format('HH:mm')
            this.periodUntil = FormatDate(data.rental.date_rent_end);
        }


        if (data.customer) {
            this.renter = this.getRenterName(data)
        }


        if (data.delivery_address_item) {
            this.stay = formatAddress(data.delivery_address_item)
        }

        if (data.transactions) {
            this.paymentStatus = data.paymentStatus
            this.paymentDate = this.getPaymentDate(Object.values(data.transactions), data.rental.transaction_id)
        }

        if (data.delivery_address) {

            if (data.delivery_address_item) {
                data.delivery_address.item = data.delivery_address_item
            }

            if (data.delivery_address_item_number) {
                data.delivery_address.item_number = data.delivery_address_item_number
            }

            this.holidayAddress = this.getHolidayAddress(data.delivery_address)

        } else if (data.pickup_address) {

            if (data.pickup_address_item) {
                data.pickup_address.item = data.pickup_address_item
            }

            if (data.pickup_address_item_number) {
                data.pickup_address.item_number = data.pickup_address_item_number
            }

            this.holidayAddress = this.getHolidayAddress(data.pickup_address)
        }

        this.uuid = data.rental.uuid


    }

    getRenterName = (data) => {

        if (data.customer.type === 'company') {
            return data.customer_company.name
        } else {
            if (data.customer_private_person) {
                return DisplayName(data.customer_private_person)
            }
        }
    }

    getPaymentStatus = (transactions, transactionId) => {

        const transaction = transactions[0];

        if (transaction) {
            this.last_transaction_id = transaction.id
            return transaction.status
        } else {
            return null
        }
    }


    getPaymentDate = (transactions, transactionId) => {

        const transaction = transactions[0];

        if (transaction) {
            return FormatDate(transaction.date_created)
        } else {
            return null
        }
    }

    formatRentalItems = (items) => {
        if (items) {
            return items.filter((item) => item.type === 'category' ||  item.type === 'insurance' ||  item.type === 'service').map((item) => {
                return new CategoryModel(item)
            })
        }
    }

    getHolidayAddress = (data) => {

        if (data) {

            let returnData = ''

            if (data.name) {
                returnData = data.name + ', ' + data.street + ' ' + data.number + ', ' + data.zip_code
            } else {
                returnData = data.street + ' ' + data.number + ', ' + data.zip_code
            }

            if (data.item) {
                returnData += ' - '
            }

            if (data.item && data.item.name) {
                returnData += ' ' + data.item.name
            }

            if (data.item && data.item.number) {
                returnData += ' ' + data.item.number
            }

            if (data.item_number) {
                returnData += ' - ' + data.item_number
            }

            return returnData

        } else {
            return ''
        }

    }

    countItems = (items) => {

        if (items && items.length) {

            let count = 0;

            items.filter((item) => item.type === 'category').forEach(item => {
                count += item.quantity
            })

            return count

        } else {
            return 0
        }

    }

}
