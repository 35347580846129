import moment from "moment"
import translate from "../../createSlice/common/helpers/utils/translate";

const DateRangeData = () => {

    const todayStart = moment().startOf('day').toDate()
    const todayEnd = moment().endOf('day').toDate()

    const tommorrowStart = moment().add(1, 'days').startOf('day').toDate()
    const tommorrowEnd = moment().add(1, 'days').endOf('day').toDate()

    const thisWeek = {
        start: moment().startOf('week').add(1, 'days').toDate(),
        end: moment().endOf('week').add(1, 'days').toDate()
    }
    const nextWeek = {
        start: moment().add(1, 'weeks').startOf('week').add(1, 'days').toDate(),
        end: moment().add(1, 'weeks').endOf('week').add(1, 'days').toDate()
    }

    const ranges = [
        // {
        //     label: 't/m vandaag',
        //     value: [moment('2021-01-01').toDate(), todayEnd]
        // },
        {
            label: translate('today'),
            value: [todayStart, todayEnd]
        },
        {
            label:  translate('tomorrow'),
            value: [tommorrowStart, tommorrowEnd]
        },
        {
            label: translate('thisWeek'),
            value: [thisWeek.start, thisWeek.end]
        },
        {
            label: translate('nextWeek'),
            value: [nextWeek.start, nextWeek.end]
        },

    ];

    const locale = {
        sunday: translate('su'),
        monday: translate('mo'),
        tuesday: translate('tu'),
        wednesday: translate('we'),
        thursday: translate('th'),
        friday: translate('fr'),
        saturday: translate('sa'),
        ok: 'OK',
        hours: translate('hour'),
        minutes: translate('minutes'),
        seconds: translate('seconds')
    };

    return {ranges, locale}

}

export default DateRangeData
