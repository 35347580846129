export const getLanguageCode = () => {

    let language = 'nl'

    let germanySlugCheck = 'germany'
    let denmarkSlugCheck = 'denmark'
    let englishSlugCheck = 'dormio-bercksurmer'


    if (window.location.href.indexOf("/8/") > -1) {
        language = 'en'
    }

    if (window.location.href.indexOf("/3/") > -1 && window.location.href.indexOf(englishSlugCheck) > -1) {
        language = 'en'
    }

    if (window.location.href.indexOf("/3/") > -1 && window.location.href.indexOf(germanySlugCheck) > -1) {
        language = 'de'
    }

    if (window.location.href.indexOf("/3/") > -1 && window.location.href.indexOf(denmarkSlugCheck) > -1) {
        language = 'DK'
    }

    return language
}