// Dependencies
import React from 'react'
import {connect} from "react-redux";
import ReactPaginate from 'react-paginate';

// Actions
import TransactionAction from "../../stores/transaction/TransactionAction";

// Helpers
import DisplayPrice from "../../common/helpers/DisplayEuro";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";
import DateRangeData from "../../common/constants/DateRangeData";
import {DateRangePicker} from "rsuite";
import FormatDate from "../../common/helpers/FormatDate";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import {useAppAxios} from "../../createSlice/app/hooks";
import ApiEnum from "../../common/constants/ApiEnum";
import moment from "moment/moment";
import Loader from "react-loader-spinner";

const mapStateToProps = (state) => ({
    environment: state.environment,
    transaction: state.transaction
});

const {ranges, locale} = DateRangeData()

class TransactionOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exporting: false
        };
    }

    componentDidMount() {
        this.props.dispatch(TransactionAction.requestListByLocation())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.transaction.listPeriod !== this.props.transaction.listPeriod) {
            this.props.dispatch(TransactionAction.requestListByLocation())
        }
    }

    render() {
        const {primary_color_dark} = this.props.environment.data.info;
        const {transaction} = this.props;
        const {list, totals, listSearchValue} = transaction;
        const {pages} = list;

        const disabledDate = (date, selectValue, selectedDone) => {
            const MAX_DATE_RANGE = 62

            if (!selectedDone) {
                return moment(date).diff(selectValue[0], 'days') > MAX_DATE_RANGE || moment(selectValue[0]).diff(date, 'days') > MAX_DATE_RANGE;
            }

            return false;
        };

        return (
            <main style={{padding: '2rem 0'}}>

                <Scanner type={'rental'}/>

                <div className="o-container o-container--primary">

                    <div className="transaction-overview__header">

                        <div className="c-transaction-filter">

                            <label>
                                {translate('startDateBookingPeriod')}
                                <DateRangePicker
                                    isoWeek={true}
                                    defaultValue={[this.props.transaction.listPeriod.start, this.props.transaction.listPeriod.end]}
                                    value={[this.props.transaction.listPeriod.start, this.props.transaction.listPeriod.end]}
                                    ranges={ranges}
                                    locale={locale}
                                    onChange={(data) => this._changeDate(data)}
                                    renderValue={(value) => {
                                        return FormatDate(value[0]) + ' ~ ' + FormatDate(value[1]);
                                    }}
                                    disabledDate={disabledDate}
                                />
                            </label>

                            {/*<label>{translate('search')}*/}
                            {/*    <input type={'text'} value={listSearchValue}*/}
                            {/*           onChange={(e) => this._changeSearchValue(e)}*/}
                            {/*           onKeyDown={(e) => this._changeSearchValue(e)}/>*/}
                            {/*</label>*/}

                        </div>

                        <button className={'btn btn--small'} disabled={this.state.exporting} onClick={() => {
                            this.setState({exporting: true})
                            this._export(transaction).then(() => {
                                this.setState({exporting: false})
                            })
                        }} style={{
                            background: primary_color_dark,
                            color: '#fff',
                        }}>
                            {this.state.exporting ? <Loader type={"ThreeDots"}
                                                            color={"White"}/> :
                                <span>{translate('downloadExportFile')}</span>}
                        </button>

                    </div>

                    {(!!list.data && !!list.data.length) &&
                        <>
                            <h4 style={{marginTop: '4rem', padding: 0}}>Transacties</h4>
                            <table className={'transaction-overview transaction-overview--hover'}>

                                <tr>
                                    <th>{translate('bookingsNumber')}</th>
                                    <th>{translate('startDateBookingPeriod')}</th>
                                    <th>{translate('endDateBookingPeriod')}</th>
                                    <th>{translate('transactionNumber')}</th>
                                    <th>{translate('transactionStatus')}</th>
                                    <th>{translate('renter')}</th>
                                    <th>{translate('paymentMethod')}</th>
                                    <th>{translate('alreadyPaid')}</th>
                                    <th>{translate('refundedBookingAmount')}</th>
                                    <th>{translate('depositPaid')}</th>
                                    <th>{translate('depositAmountRefunded')}</th>
                                    <th>{translate('insurancePremium')}</th>
                                </tr>

                                {!!list.data && list.data.map((item) => {

                                    const {
                                        rental_id,
                                        customer,
                                        id,
                                        payment_method,
                                        status,
                                        total,
                                        deposit,
                                        total_returned,
                                        deposit_returned,
                                        rental_date_rent_start,
                                        rental_date_rent_end,
                                        insurance
                                    } = item

                                    return (
                                        <tr onClick={() => this._clickItemRow(item)} key={'transaction-' + id}>
                                            <td>{rental_id}</td>
                                            <td>{FormatDate(rental_date_rent_start)}</td>
                                            <td>{FormatDate(rental_date_rent_end)}</td>
                                            <td>{id}</td>
                                            <td>{status}</td>
                                            <td>{customer}</td>
                                            <td>{payment_method}</td>
                                            <td>{DisplayPrice(total)}</td>
                                            <td>{DisplayPrice(total_returned)}</td>
                                            <td>{DisplayPrice(deposit)}</td>
                                            <td>{DisplayPrice(deposit_returned)}</td>
                                            <td>{DisplayPrice(insurance)}</td>
                                        </tr>
                                    )
                                })}

                            </table>
                        </>
                    }

                    {!!list.totals &&
                        <>
                            <h4 style={{marginTop: '4rem', padding: 0}}>{translate('totals')}</h4>

                            <table className={'transaction-overview'}>

                                <tr>
                                    <th>{translate('paymentMethod')}</th>
                                    <th>{translate('balanceRental')}</th>
                                    <th>{translate('balanceDeposit')}</th>
                                    <th>{translate('insurancePremium')}</th>
                                </tr>

                                {Object.keys(list.totals).map((key) => {

                                    const totalItem = list.totals[key]

                                    return (
                                        <tr key={'total-item-' + key}>
                                            <td>{key}</td>
                                            <td>{DisplayPrice(totalItem.amount_total)}</td>
                                            <td>{DisplayPrice(totalItem.amount_deposit)}</td>
                                            <td>{DisplayPrice(totalItem.amount_insurance)}</td>
                                        </tr>
                                    )

                                })}

                            </table>

                        </>
                    }

                    {!!pages &&
                        <ReactPaginate
                            previousLabel={'< ' + translate('previous')}
                            nextLabel={translate('next') + ' >'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pages.total}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(data) => this._handlePageClick(data)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            forcePage={parseInt(pages.current)}
                            activeClassName={'active'}
                        />
                    }

                </div>
            </main>
        )
    }

    _clickItemRow = (item) => {
        this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalDetail + '/' + item.rental_uuid)
    }

    _changeDate = (data) => {
        this.props.dispatch(TransactionAction.setPeriod(data)).then(() => {
            this.props.dispatch(TransactionAction.requestListByLocation())
        })
    }

    // _changeSearchValue = (e) => {
    //     this.props.dispatch(TransactionAction.setSearchValue(e.target.value)).then(() => {
    //
    //             if (e.key === 'Enter') {
    //                 this.props.dispatch(TransactionAction.requestListByLocation())
    //             }
    //         }
    //     )
    // }

    _handlePageClick = (i) => {
        this.props.dispatch(TransactionAction.setPage(i.selected)).then(() => {
            this.props.dispatch(TransactionAction.requestListByLocation())
        })
    }

    _export = async (transaction) => {
        return await useAppAxios.post(ApiEnum.Api + '/transaction/export-by-location', {
            date_from: moment(transaction.listPeriod.start).format('YYYY-MM-DD'),
            date_to: moment(transaction.listPeriod.end).format('YYYY-MM-DD'),
            filter: transaction.listSearchValue,
            include_transactions_pending: true,
            base_date_filter: "rental_date_start"
        }, {
            skipCamel: true
        }).then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            return error
        })

    }

}

export default connect(mapStateToProps)(TransactionOverview);
